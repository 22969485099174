.registrationSelect {
  width: 50%;
  margin: 20px auto;
  min-width: 300px;
  background-color: $RAWhiteColor;

  select {
    width: 100%;
    height: 36px;
    margin: 0 !important;
    cursor: pointer;
    background-position-y: 8px !important;
  }
}