.image__upload {
  &-wrapper {
    &.center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-preview {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid $RAGrayColor;
    background-color: $RAWhiteColor;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    @include font($RAGrayColor, 700, 16px, 19px);
    min-width: 300px;
    width: 50%;
    text-align: center;
    margin: 0 auto 20px;
  }
}
