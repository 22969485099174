.myCases {

  &__finderArea {
    @include flexAlignItem(flex-end, center);
  }

  &__content {
    height: calc(100vh - 300px);
    overflow-x: hidden;
    overflow-y: auto;
    @include customScroll();
    margin-top: 30px;
  }
}