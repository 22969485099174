.customSelect {
  @include font($RAGrayColor, 700, 16px, 19px);

  & > select {
    width: 260px;
    height: 30px;
    padding: 0 20px;
    margin-left: 5px;
    @include font($RAGrayColor, 400, 14px, 17px);
    outline: none;
    border: 1px solid $RAGrayColor;
    border-radius: 3px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;

    option {
      @include font($RAGrayColor, 400, 14px, 20px);
    }
  }
}