.drugMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {
    margin: 30px 0 50px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__addButton {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    position: relative;

    &:after {
      content: 'add drug';
      position: absolute;
      color: $RAGrayColor;
    }
  }

  &__drug:first-child {
    font-weight: 700;

    div:nth-child(2), div:nth-child(3) {
      text-align: center;
    }
  }

  &__drug {
    height: 40px;
    @include font($RAGrayColor, 400, 20px, 20px);
    border-bottom: 1px solid $RAGrayColor;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: block;
    }

    &-id {
      width: 40px;
    }

    &-name {
      width: 200px;
    }

    &-uCount {
      width: 60px;
      text-align: center;
    }

    &-color, &-edit, &-del {
      width: 70px;
      text-align: center;
      @include flexAlignItem(center, center);

      span {
        width: 20px;
        height: 20px;
      }

      img {
        cursor: pointer;
      }
    }
  }
}