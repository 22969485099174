.navButtonWrapper {
  min-width: 40px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton {
  cursor: pointer;

  &__disable {
    cursor: not-allowed;
  }
}