.caseCard {
  width: 310px;
  height: 140px;
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  margin: 0 25px 25px 0;
  padding: 15px;
  background-color: $RAWhiteColor;
  cursor: pointer;

  &__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    p:nth-child(1) {
      @include font($RAGrayColor, 400, 10px, 12px);
    }

    span {
      width: 4px;
      height: 4px;
      background-color: $RAGrayColor;
      margin: 0 5px;
      border-radius: 50%;
    }

    p:nth-child(3) {
      @include font($RAGrayColor, 400, 12px, 14px);
      text-transform: capitalize;
    }
  }

  &__titleLine {
    height: 1px;
    margin-top: 7px;
    margin-bottom: 14px;
  }

  &__infoArea {
    flex-grow: 1;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__caseTitle, &__caseAuthor {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    h6 {
      @include font($RAGrayColor, 700, 14px, 17px)
    }

    p {
      margin-left: 11px;
      @include font($RAGrayColor, 400, 14px, 17px)
    }
  }
}