.accountant {
  &__sortingArea {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .customTable {
    margin-top: 50px;

    &__contentRow {
      td:nth-child(2), td:nth-child(3), td:last-child {
        div {
          cursor: pointer;
        }
      }
    }

    &__contentRow {
      td:last-child {
        div {
          @include flexAlignItem(flex-start, center);
        }
      }
    }

    &__payed {
      cursor: default !important;
    }
  }
}