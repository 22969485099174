.addUserModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  @include flexAlignItem(center, center);

  &__content {
    padding: 40px;
    max-width: 600px;
    border: 1px solid $RAGrayColor;
    background-color: $RAWhiteColor;
    border-radius: 3px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      margin-bottom: 20px;
      @include font($RAGrayColor, 700, 25px, 25px);
    }

    &-buttonArea {
      width: 100%;
      @include flexAlignItems(row, nowrap, space-between, center);
    }
  }

}