.invoiceForm, .contractForm {
  & .dashTitle {
    width: 50%;
    margin: 20px auto 10px;
    padding: 0 20px;

    &__textArea {
      margin: 0 50px;
    }

    &__subTitle {
      margin-top: 5px;
    }
  }
}