.radioGroup {
  margin: 0 auto 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    width: 50%;
    min-width: 300px;
    text-align: center;
    @include font($RAGrayColor, 700, 16px, 19px);
    margin: 0 auto 10px;
  }

  &__radioButtonArea {
    width: 50%;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;

    label {
      @include font($RAGrayColor, 700, 16px, 19px);
      max-width: 250px;
      min-width: 150px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      cursor: pointer;

      input {
        width: 20px;
        height: 20px;
        border: 1px solid $RAGrayColor;
        background-color: $RAWhiteColor;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        &:checked:after {
          position: absolute;
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          top: calc(50% - 7px);
          left: calc(50% - 7px);
          background-color: $RAGrayColor;
        }
      }
    }
  }
}