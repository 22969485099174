//@include font($color1, 600, 22px, 22px);
@mixin font($color, $weight, $size, $lineH) {
  color: $color;
  font-weight: $weight;
  font-size: $size;
  line-height: $lineH;
}

//align items
@mixin flexAlignItems($direction, $wrap, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $align;
}

//align single item
@mixin flexAlignItem($hAlign, $vAlign) {
  display: flex;
  justify-content: $hAlign;
  align-items: $vAlign;
}

@mixin customScroll() {
  &::-webkit-scrollbar {

    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    background-image: url("../../assets/img/scroll.png");
    border-radius: 5px;
  }
}
