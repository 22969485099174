.statusModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  @include flexAlignItem(center, center);

  &__content {
    padding: 40px;
    border: 1px solid $RAGrayColor;
    background-color: $RAWhiteColor;
    border-radius: 3px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      margin-bottom: 30px;
      @include font($RAGrayColor, 700, 25px, 25px);
    }

    &-buttonArea {
      margin-top: 10px;
    }

    button:last-child {
      margin-left: 40px;
    }

    & > .formControl {
      min-height: 0;
      height: 40px;
      margin-top: 20px;
    }

    & > .formControl > label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0;
      min-width: 0;
      width: 100%;

      & > input {
        width: 150px;
        margin: 0 0 0 20px;
        min-width: 0;
        max-width: fit-content;
        text-align: center;
      }
    }
  }
}