.registration {

  &__form {
    margin-top: 30px;
    //background-color: lime;
    overflow: hidden;

    &-content {
      overflow-y: auto;
      height: calc(100vh - 300px);
      @include customScroll();
    }
  }

  &__acceptPPLabel {
    width: 50%;
    margin: 20px auto;
    display: block;
    cursor: pointer;
    padding-left: 40px;
    padding-top: 10px;
    position: relative;

    a {
      margin-left: 10px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      top: 5px;
      left: 5px;
      border: 1px solid $RAGrayColor;
      border-radius: 6px;
    }


    &-checked:after {
      content: '';
      position: absolute;
      width: 19px;
      height: 19px;
      top: 8px;
      left: 8px;
      border-radius: 6px;
      background: $RAGrayColor;
    }
  }

  &__buttonArea {
    min-width: 300px;
    width: 50%;
    margin: 30px auto 0 auto;
    display: flex;
    justify-content: space-between;
  }
}