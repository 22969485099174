.authButton, .button, .authLinkButton, .linkButton, .showModalButton {
  outline: none;
  border: none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  @include font($RAGrayColor, 700, 20px, 24px);

  &:disabled {
    cursor: not-allowed;
  }
}

.authButton, .authLinkButton {
  color: $RAWhiteColor !important;
}

.authLinkButton, .linkButton {
  font-weight: 400 !important;
}

.showModalButton {
  margin-top: 5px;
  text-align: start;
  width: 210px;
  @include font($RAGrayColor, 400, 14px, 17px);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
  }
}