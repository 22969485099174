.formControl,
.authFormControl {
  width: 50%;
  min-width: 300px;
  max-width: 750px;
  margin: 0 auto;
  position: relative;

  input, label, textarea, p {
    display: block;
    width: 100%;

  }

  input, textarea {
    outline: none;
    padding: 10px;
    border-radius: 3px;
  }

  label {
    @include font($RAGrayColor, 700, 16px, 19px);
    margin-bottom: 8px;
  }

  img {
    width: 25px;
    position: absolute;
    cursor: pointer;
  }

}

.formControl {
  min-height: 80px;
  margin-bottom: 8px;


  input {
    height: 36px;
  }

  input, textarea {
    border: 1px solid $RAGrayColor;
    color: $RAGrayColor;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }

  img {
    top: 35px;
    left: 15px;
  }
}

.authFormControl {
  min-height: 66px;
  margin-bottom: 30px;

  input {
    height: 50px;
  }

  input, textarea {
    border: 2px solid $RAWhiteColor;
    background-color: transparent;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;

    &::placeholder {
      color: $RAWhiteColor;
    }
  }

  input, label, textarea {
    color: $RAWhiteColor;
  }

  img {
    top: 15px;
    left: 15px;
  }
}

.invalidFormControl {

  input, textarea {
    border: 2px solid $RADangerColor;
    color: $RADangerColor;

    &::placeholder {
      color: $RADangerColor;
    }
  }

  p, label {
    color: $RADangerColor;
  }
}