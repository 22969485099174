.quillWrapper {
  margin-top: 10px;
  width: 100%;
  height: 260px;

  .ql-container {
    height: 218px;
    background: $RAWhiteColor;
  }

  .ql-editor {
    @include customScroll();
  }
}