.modalInput {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  label {
    @include font($RAGrayColor, 400, 14px, 17px);
    margin-right: 20px;
  }

  input {
    height: 30px;
    width: 60px;
    outline: none;
    border: 1px solid $RAGrayColor;
    border-radius: 3px;
    @include font($RAGrayColor, 300, 16px, 19px);
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button, {
        -webkit-appearance: none;
    }
  }
}