.finder {
  width: 260px;
  height: 30px;
  outline: none;
  padding: 0 5px 0 35px;
  border: 1px solid $RAGrayColor;
  border-radius: 3px;
  @include font($RAGrayColor, 400, 14px, 17px);
  position: relative;
  background: url("../../../assets/img/finder.png") no-repeat;
  background-size: 26px 26px;
  background-position-x: 3px;
  background-position-y: 2px;
}