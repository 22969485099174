header.container {
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header {

  &__logo {
    width: 129px;
    height: 54px;
    margin-right: 30px;
  }


  &__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &-link {
      text-decoration: none;
      margin-right: 50px;
      //width: 170px;
      @include font($RAGrayColor, 400, 18px, 21px);

      &:hover {
        color: $RAGreenColor;
        font-weight: 700;
      }
    }

    &-activeLink {
      color: $RAGreenColor;
      font-weight: 700;
    }
  }

  &__subMenu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover > ul {
      height: auto;
    }

    &:hover > &-menuArrow {
      transform: rotate(180deg);
      transition: .5s;
    }

    &-title {
      margin-right: 10px;
      width: 90px;
      @include font($RAGrayColor, 400, 18px, 21px);
    }

    &-menuArrow {
      transition: .5s;
      width: 20px;
      height: 20px;
      background-size: contain;
      background-position: center;
    }

    &-titleImage {
      transition: .5s;
      width: 15px;
      height: 15px;
    }

    ul {
      position: absolute;
      text-align: center;
      top: 20px;
      left: -50px;
      width: 220px;
      height: 0;
      overflow: hidden;
      padding: 0 17px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
      background-color: $RAWhiteColor;
      z-index: 10;

      li {
        padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      li:not(:last-child) {
        border-bottom: 1px solid #EBEBEB;
      }

      li > .header__menu-link {
        margin: 0;
      }
    }
  }
}